// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  enableErrorReporting: false,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  serverUrl: 'https://live.bluehalo.global/api',
  webUrl: 'https://live.bluehalo.global',
  geoLocationUrl: 'https://maps.googleapis.com/maps/api/js?libraries=places',
  geoLocationApiKey: 'AIzaSyCve5JqjikhpCS0zHFTO-P0wNAUCbnOCio',
  socketUrl: 'wss://live.bluehalo.global/socket',
  socketLogs: true,
  isRXA: true,
  isRXChat: true,
  urlRXA: 'https://analytics.bluehalo.global',
  urlRXM: 'https://loto.bluehalo.global',
  firebase: {
    apiKey: 'AIzaSyBazKmBOkmoGp-DypzlGB55WO8ouQ3a-30',
    authDomain: 'resolvedx---prod.firebaseapp.com',
    databaseURL: 'https://resolvedx---prod.firebaseio.com',
    projectId: 'resolvedx---prod',
    storageBucket: 'resolvedx---prod.appspot.com',
    messagingSenderId: '977853674784',
    appId: '1:977853674784:web:ff7ed03468300a114f1345',
    measurementId: 'G-92VQ8C8TK5',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
